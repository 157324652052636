import Vue, { watch } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import vueInputAutoWidth from "./plugins/vueInputAutoWidth";
import "./plugins/vuelidate";
import pinia from "./store";
import router from "./router";

import { checkAuth, checkAuthAfterMount } from "@/router/checkAuth";

Vue.config.productionTip = false;

Vue.use(vueInputAutoWidth);

Vue.mixin({
  computed: {
    isProduction() {
      return process.env.VUE_APP_MODE === "production";
    }
  }
});

const app = new Vue({
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  pinia,
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");

checkAuthAfterMount(router);

router.beforeEach((to, from, next) => {
  return checkAuth(to, next);
});
