import VueRouter, { NavigationGuardNext, Route } from "vue-router";
import { useUserStore } from "@/store/useUserStore";

export function checkAuth(to: Route, next: NavigationGuardNext) {
  if (to.path === "/login") {
    next();
    return true;
  }

  const userStore = useUserStore();

  if (userStore.isAuthenticated) {
    next();
    return true;
  }

  if (!localStorage.getItem("token")?.length) {
    next("/login");
    return false;
  }

  userStore.SET_TOKEN(localStorage.getItem("token"));
  userStore.UPDATE_AUTH(true);
  userStore
    .updateToken()
    .then(() => {
      next();
    })
    .catch(() => {
      next("/login");
    });
}

export function checkAuthAfterMount(router: VueRouter) {
  const userStore = useUserStore();

  if (userStore.isAuthenticated) {
    return true;
  }

  if (!localStorage.getItem("token")?.length) {
    router.push("/login");
    return false;
  }

  userStore.SET_TOKEN(localStorage.getItem("token"));
  userStore.UPDATE_AUTH(true);
  userStore.updateToken().catch(() => {
    router.push("/login");
  });
}
