import { AuthResponseInterface } from "@/models/api/AuthResponse.interface";
import { ErrorResponseInterface } from "@/models/api/ErrorResponse.interface";
import api from "@/api/baseApi";
import { AuthRequestInterface } from "@/models/auth/AuthRequest.interface";
import { UserInterface } from "@/models/store/User.interface";
import { RegisterRequestInterface } from "@/models/auth/RegisterRequest.interface";
import { BaseResponseInterface } from "@/models/api/BaseResponse.interface";
import {
  AxiosRequestConfig,
  AxiosResponse,
  RawAxiosRequestHeaders
} from "axios";
import { TemplateInterface } from "@/models/store/TemplatesTableHeader.interface";
import { DataResponseInterface } from "@/models/api/DataResponse.interface";
import {
  RemoveTemplateRequestInterface,
  RemoveTemplateResponseInterface
} from "@/models/api/template/RemoveTemplateRequest.interface";
import {
  SaveOrUpdateTemplateRequestInterface,
  SaveOrUpdateTemplateResponseInterface
} from "@/models/api/template/SaveOrUpdateTemplateRequestInterface";
import {
  RenameTemplateRequestInterface,
  RenameTemplateResponseInterface
} from "@/models/api/template/RenameTemplateRequestInterface";
import { OrderRequestInterface } from "@/models/order/OrderRequest.interface";

export async function login(
  req: AuthRequestInterface
): Promise<AuthResponseInterface | ErrorResponseInterface> {
  const res: AxiosResponse<
    AuthResponseInterface | ErrorResponseInterface
  > = await api.post("/Account/Login", req);

  return res.data;
}

export async function register(
  req: RegisterRequestInterface
): Promise<AuthResponseInterface | ErrorResponseInterface> {
  const res: AxiosResponse<
    AuthResponseInterface | ErrorResponseInterface
  > = await api.post("/Account/register", req);

  return res.data;
}

export async function updateToken(
  token: string
): Promise<AuthResponseInterface | ErrorResponseInterface> {
  const res: AxiosResponse<
    AuthResponseInterface | ErrorResponseInterface
  > = await api.post("/Account/Refresh", {}, {
    headers: {
      Authorization: "Bearer " + token
    } as RawAxiosRequestHeaders
  } as AxiosRequestConfig);
  return res.data;
}

export async function getUsers(): Promise<UserInterface[]> {
  return (await api.post("/User/list")).data;
}

export async function confirmUser(
  userId: string
): Promise<BaseResponseInterface | ErrorResponseInterface> {
  return (await api.post(`User/confirm?id=${userId}`)).data;
}

export async function getTemplates(
  signal?: AbortSignal
): Promise<
  DataResponseInterface<TemplateInterface[]> | ErrorResponseInterface
> {
  const res: AxiosResponse<
    DataResponseInterface<TemplateInterface[]> | ErrorResponseInterface
  > = await api.get(`/template/list`, { signal: signal } as AxiosRequestConfig);

  if (signal?.aborted) {
    const err = new Error("Abort message");
    err.name = "AbortError";
    throw err;
  }

  return res?.data ?? [];
}

export async function getTemplateData(
  templateId: string,
  signal?: AbortSignal
): Promise<
  DataResponseInterface<OrderRequestInterface> | ErrorResponseInterface
> {
  const res: AxiosResponse<
    DataResponseInterface<OrderRequestInterface> | ErrorResponseInterface
  > = await api.get(`/template/${templateId}`, {
    signal: signal
  } as AxiosRequestConfig);

  if (signal?.aborted) {
    const err = new Error("Abort message");
    err.name = "AbortError";
    throw err;
  }

  return res.data;
}

export async function removeTemplate(
  req: RemoveTemplateRequestInterface
): Promise<RemoveTemplateResponseInterface | ErrorResponseInterface> {
  const res: AxiosResponse<
    RemoveTemplateResponseInterface | ErrorResponseInterface
  > = await api.post(`/template/delete/${req.id}`);

  return res.data;
}

export async function renameTemplate(
  req: RenameTemplateRequestInterface
): Promise<RenameTemplateResponseInterface | ErrorResponseInterface> {
  const res: AxiosResponse<
    RenameTemplateResponseInterface | ErrorResponseInterface
  > = await api.post(`/template/rename`, req);

  return res.data;
}

export async function saveTemplate(
  req: SaveOrUpdateTemplateRequestInterface
): Promise<SaveOrUpdateTemplateResponseInterface | ErrorResponseInterface> {
  const res: AxiosResponse<
    SaveOrUpdateTemplateResponseInterface | ErrorResponseInterface
  > = await api.post(`/template/save`, req);

  return res.data;
}

export async function updateTemplate(
  req: SaveOrUpdateTemplateRequestInterface
): Promise<SaveOrUpdateTemplateResponseInterface | ErrorResponseInterface> {
  const res: AxiosResponse<
    SaveOrUpdateTemplateResponseInterface | ErrorResponseInterface
  > = await api.post(`/template/update`, req);

  return res.data;
}
