import { defineStore } from "pinia";
import {
  UserActionsInterface,
  UserGettersInterface,
  UserStateInterface
} from "@/models/store/UserState.interface";
import {
  UserFormInterface,
  UserInterface
} from "@/models/store/User.interface";
import { CurrentUserInterface } from "@/models/store/CurrentUser.interface";
import { AuthRequestInterface } from "@/models/auth/AuthRequest.interface";
import {
  AuthEntityInterface,
  AuthResponseInterface
} from "@/models/api/AuthResponse.interface";
import { ErrorResponseInterface } from "@/models/api/ErrorResponse.interface";
import { getTemplates, getUsers, login, updateToken } from "@/api/user";
import { pick } from "lodash/fp";
import { parseDateString } from "@/utils/dateUtils";
import {
  TemplateFormInterface,
  TemplateInterface
} from "@/models/store/TemplatesTableHeader.interface";

export const useUserStore = defineStore<
  "user",
  UserStateInterface,
  UserGettersInterface,
  UserActionsInterface
>("user", {
  state(): UserStateInterface {
    return {
      isAuthenticated: false,
      token: "",
      processing: false,
      authError: false,
      expire: "",
      updateTimer: 0,
      user: null,
      users: [],
      ownerCountry: "",
      templates: []
    };
  },
  getters: {
    getToken: state => state.token
  },
  actions: {
    UPDATE_AUTH(isAuth: boolean) {
      this.isAuthenticated = isAuth;
      if (!isAuth) {
        this.token = "";
        this.expire = "";
        localStorage.setItem("token", "");
        localStorage.setItem("expire", "");
      }
    },
    SET_TOKEN(token: string) {
      this.token = token;
      localStorage.setItem("token", token);
    },
    SET_EXPIRE(expire: string) {
      this.expire = expire;
      localStorage.setItem("expire", expire);
    },
    SET_PROCESSING(isProcessing: boolean) {
      this.processing = isProcessing;
    },
    SET_AUTH_ERROR(isAuthError: boolean) {
      this.authError = isAuthError;
    },
    SET_UPDATE_TIMER(timer: number) {
      this.updateTimer = timer;
    },
    SET_USERS(users: UserFormInterface[]) {
      this.users = users;
    },
    SET_USER(user: CurrentUserInterface) {
      this.user = user;
    },
    SET_OWNER_COUNTRY(ownerCountry: string) {
      this.ownerCountry = ownerCountry;
    },
    SET_TEMPLATES(templates: TemplateFormInterface[]) {
      this.templates = templates;
    },
    async login(authRequest: AuthRequestInterface): Promise<void> {
      this.SET_AUTH_ERROR(false);
      this.SET_PROCESSING(true);

      try {
        const res: AuthResponseInterface | ErrorResponseInterface = await login(
          authRequest
        );

        if (!res.isSuccess) {
          this.SET_TOKEN("");
          this.UPDATE_AUTH(false);
          this.SET_EXPIRE("");
          throw new Error(res.message);
        }

        this.SET_TOKEN(res.entity.token);
        this.SET_EXPIRE(res.entity.expire);
        this.SET_USER(
          pick(["name", "email"] as (keyof AuthEntityInterface)[], res.entity)
        );
        this.UPDATE_AUTH(true);
        this.autoUpdate();
      } finally {
        this.SET_PROCESSING(false);
      }
    },
    async updateToken(): Promise<void> {
      this.SET_AUTH_ERROR(false);
      this.SET_PROCESSING(true);

      try {
        const res:
          | AuthResponseInterface
          | ErrorResponseInterface = await updateToken(this.getToken);

        if (!res.isSuccess) {
          this.SET_TOKEN("");
          this.SET_EXPIRE("");
          this.SET_AUTH_ERROR(true);
          this.UPDATE_AUTH(false);
          throw new Error(res.message);
        }

        this.SET_TOKEN(res.entity.token);
        this.SET_EXPIRE(res.entity.expire);
        this.SET_USER(
          pick(["name", "email"] as (keyof AuthEntityInterface)[], res.entity)
        );
        this.autoUpdate();
      } catch (e) {
        this.UPDATE_AUTH(false);
        throw e;
      } finally {
        this.SET_PROCESSING(false);
      }
    },
    autoUpdate() {
      const timer: number = setTimeout(() => {
        this.updateToken(this.getToken).catch(() => {
          this.UPDATE_AUTH(false);
          this.SET_AUTH_ERROR(true);
        });
      }, 50 * 60 * 1000 /* 50 минут */);
      this.SET_UPDATE_TIMER(timer);
    },
    async logout() {
      this.UPDATE_AUTH(false);
    },
    async getUsers() {
      const users = (await getUsers()).map(
        (user: UserInterface): UserFormInterface => {
          return {
            ...user,
            creationDate: +(parseDateString(user.creationDate) ?? 0),
            isLoading: false
          } as UserFormInterface;
        }
      );

      this.SET_USERS(users);
    },
    async getTemplates() {
      const res = await getTemplates();
      if (!res.isSuccess) {
        return;
      }

      const templates = res.entity.map(
        (template: TemplateInterface): TemplateFormInterface => {
          return {
            ...template,
            createDate: +(parseDateString(template.createDate) ?? 0),
            modifyDate: +(parseDateString(template.modifyDate) ?? 0),
            lastUseDate: parseDateString(template.lastUseDate)
              ? +(parseDateString(template.lastUseDate) ?? 0)
              : null,
            isLoading: false
          } as TemplateFormInterface;
        }
      );

      this.SET_TEMPLATES(templates);
    }
  }
});
