import axios, { CreateAxiosDefaults } from "axios";

import { useUserStore } from "@/store/useUserStore";

const baseApi = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
} as CreateAxiosDefaults);

baseApi.interceptors.request.use(req => {
  const userStore = useUserStore();
  req.headers.Authorization = `Bearer ${userStore.getToken}`;
  return req;
});

baseApi.interceptors.response.use(
  res => res,
  e => {
    if (e.response && e.response.status === 401) {
      const userStore = useUserStore();
      userStore.logout();
    }
  }
);

const infotekaApi = axios.create({
  baseURL: process.env.VUE_APP_INFOTEKA_URL
} as CreateAxiosDefaults);

export { infotekaApi };

export default baseApi;
