













































import { NavigationRouteItemInterface } from "@/models/navigation/NavigationRouteItem.interface";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { CurrentUserInterface } from "@/models/store/CurrentUser.interface";

import { useUserStore } from "@/store/useUserStore";
import router from "@/router";

export default defineComponent({
  name: "App",
  setup() {
    const userStore = useUserStore();
    const drawer = ref<boolean>(true);
    const isMobile = ref(false);

    const user = computed((): CurrentUserInterface | null => {
      return userStore.user;
    });
    const isAuthenticated = computed((): boolean => {
      return userStore.isAuthenticated;
    });
    const routes = computed((): NavigationRouteItemInterface[] => {
      return user.value?.email === "support@yandex.ru"
        ? [
            {
              title: "Регистрация пользователей",
              route: "/register",
              icon: "mdi-account-plus"
            }
          ]
        : [
            {
              title: "Заказ FTL",
              route: "/order-ftl",
              icon: "mdi-package"
            },
            {
              title: "Загрузка заказов",
              route: "/import",
              icon: "mdi-file-excel"
            },
            {
              title: "Шаблоны",
              route: "/templates",
              icon: "mdi-bookmark-plus"
            }
          ];
    });
    const logout = () => {
      userStore.logout();
    };

    watch(isAuthenticated, (isAuthenticated: boolean) => {
      if (isAuthenticated) return;

      router.push("/login");
    });

    onMounted(() => {
      isMobile.value = document.documentElement.offsetWidth < 1280;
    });

    return {
      user,
      routes,
      isAuthenticated,
      drawer,
      isMobile,
      logout
    };
  }
});
