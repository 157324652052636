import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@mdi/font/css/materialdesignicons.css";

import ru from "vuetify/src/locale/ru";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { ru },
    current: "ru"
  },
  icons: {
    iconfont: "mdi"
  },
  theme: {
    themes: {
      light:
        process.env.VUE_APP_MODE === "production"
          ? {
              primary: "#ffeb3b",
              secondary: "#191919",
              accent: "#cddc39",
              error: "#ff5722",
              warning: "#ff9800",
              info: "#00bcd4",
              success: "#4caf50"
            }
          : {
              primary: "#ffde40",
              secondary: "#191919",
              accent: "#cddc39",
              error: "#ff5722",
              warning: "#ff9800",
              info: "#00bcd4",
              success: "#4caf50"
            }
    }
  }
});
