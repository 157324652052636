import convertStringToNumber from "@/utils/convertStringToNumber";
import isMatch from "date-fns/isMatch";
import format from "date-fns/format";
import differenceInHours from "date-fns/differenceInHours";
import addDays from "date-fns/addDays";
import parse from "date-fns/parse";
import { isTime } from "@/validations/dateTimeValidations";

export function isMatchDateFormat(string: string, format: string): boolean {
  try {
    return isMatch(string, format);
  } catch (e) {
    return false;
  }
}

/**
 * Convert string to date or return null if it's not valid
 * @param {string} dateString
 * @param {string} format
 * @returns {Date | null}
 */
export function parseDateString(
  dateString: string,
  format?: string
): Date | null {
  if (format) {
    const isMatch = isMatchDateFormat(dateString, format);

    return isMatch ? parse(dateString, format, new Date()) : null;
  }

  const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)(?:Z|([+-])([\d|:]*))?$/;
  const reMsAjax = /^\/Date\((d|-|.*)\)[/|\\]$/;
  const reAlfa = /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/;

  const isISO = reISO.exec(dateString);
  if (isISO) return new Date(dateString);
  const isMSAjax = reMsAjax.exec(dateString);
  if (isMSAjax) {
    const b = isMSAjax[1].split(/[-+,.]/);
    return new Date(b[0] ? +b[0] : 0 - +b[1]);
  }
  const isAlfa = reAlfa.exec(dateString);
  if (isAlfa) {
    const [year, month, day] = dateString
      .split("-")
      .map(str => convertStringToNumber(str));
    return new Date(year, month - 1, day);
  }

  return null;
}

/**
 * Return ru-locale date string.
 * @param {Date, String, Number} date
 * @return {string}
 */
export function getLocaleDateString(date: string | Date | number): string {
  const computedDate = new Date(date);

  if (computedDate.toString() === "Invalid Date") return "";

  return computedDate.toLocaleDateString("ru-RU");
}

export function formatDate(date: Date, dateFormat: string): string {
  return format(date, dateFormat);
}

export function dateDifferenceInHours(
  biggerDate: Date,
  smallerDate: Date
): number {
  return differenceInHours(biggerDate, smallerDate);
}

export function addDaysToDate(biggerDate: Date, daysCount: number): Date {
  return addDays(biggerDate, daysCount);
}

export const parseTimeToMinute = (timeStr: string) => {
  if (isTime(timeStr)) {
    const [hours, minute] = timeStr.split(":");
    return parseInt(hours) * 60 + parseInt(minute);
  }
  return 0;
};
